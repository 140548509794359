import React, { useState, useContext } from 'react';
import { Grid, Button, Slide, Dialog, DialogContent, Box } from '@material-ui/core';
import { myStyles } from './styles';
import clsx from 'clsx';
import './RequestFormPopup.scss';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import DialogPopupContext from '../../utils/dialogpopup-context';
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

export default function RequestFormPopup({ data, images }) {
  const imageData = {
    images,
    data,
  };
  const classes = myStyles(imageData);
  const popup = useContext(DialogPopupContext);
  const [showTopBtn, setShowTopBtn] = useState(false);
  const isBrowser = typeof window !== 'undefined';
  if (isBrowser) {
    window.addEventListener('scroll', () => {
      if (window.scrollY > 400) {
        setShowTopBtn(true);
      } else {
        setShowTopBtn(false);
      }
    });
  }
  return (
    <Grid elevation={0} className="request-form-btn-div">
      {showTopBtn && (
        <Button className="request-form-btn" onClick={popup.openPopup} locId="header">
          {data?.header}
        </Button>
      )}
      <Dialog open={popup.status} TransitionComponent={Transition} onClose={popup.closePopup} maxWidth="md">
        <Box
          className={clsx('request-form-popup-overlay donut', classes['bg-dot-matrix-and-donut'])}
        >
          <div
            className={clsx('popup-close-icon', classes['bg-popup-close-icon'])}
            onClick={popup.closePopup}
            tabindex='0'
          ></div>
          <DialogContent className="dialog-content">
            <Grid container>
              <Grid items xl={6} lg={6} md={12} sm={12} xs={12}>
                <div className="title" locId="header">{data?.header}</div>
                {data?.subHeader?.raw &&
                  documentToReactComponents(JSON.parse(data?.subHeader?.raw))}
              </Grid>
              <Grid items xl={6} lg={6} md={12} sm={12} xs={12} className="form-div">
                <div className="form_section">
                  {data?.iFrameLink && (
                    <iframe
                      src={data?.iFrameLink}
                      width="100%"
                      height="100%"
                      type="text/html"
                      frameborder="0"
                      scrolling="no"
                      allowTransparency="true"
                      style={{ border: 0 }}
                    />
                  )}
                </div>
              </Grid>
            </Grid>
          </DialogContent>
        </Box>
      </Dialog>
    </Grid>
  );
}
