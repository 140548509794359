import React,{ useContext } from 'react';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import './JoinOurTeam.scss';
import { myStyles } from './styles';
import clsx from 'clsx';
import { Typography } from '@mui/material';
import { Link } from 'gatsby';
import Container from '@material-ui/core/Container';
import DialogPopupContext from '../../utils/dialogpopup-context';

const JoinOurTeam = ({ data, matrixImg, basePath }) => {
  const classes = matrixImg ? myStyles(matrixImg) : " ";
  const headerName = data?.header;
  const subHeader = data?.subHeader;
  const shortDescription = data?.shortDescription?.raw;
  const addImage = data?.additionalImage?.file?.url+'?fm=webp&q=100';
  const AddImageTitle = data?.additionalImage?.description;
  const imgGetTouch = data?.mainImage?.file?.url+'?fm=webp&q=100';
  const imgGetTouchTitle = data?.mainImage?.description;
  const buttonLabel = data?.ctaButton?.labelForPrimaryCta;
   const dialog = useContext(DialogPopupContext);

  return (
    <>
      <Grid className={clsx('secure-container-about', classes['matrix-joinus'])}>
        <Grid className="join-our-team-cls">
          <Grid container columnSpacing={{ xl: 3, lg: 3, md: 2 }}>
            <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
              <div className="join-wrap">
                <h2 className="title-get-touch" locId="headerName">
                  <img
                    className="plus-image"
                    src={addImage}
                    alt={AddImageTitle}
                    locId="additionalImage"
                  />
                  {headerName}
                </h2>
                <h3 className="sub-title-get-touch" locId="subHeader">
                  {subHeader}
                </h3>
                { shortDescription ? (documentToReactComponents(JSON.parse(shortDescription))) : ""}
                {data?.ctaOrPardotForm == false ? (
                  <Button
                    variant="outlined"
                    className="btn-join-us"
                    locId="displayPrimaryCta"
                    tabindex="-1"
                    onClick={dialog.openPopup}
                  >
                    {buttonLabel}
                  </Button>            
                
                ):
                data?.ctaButton?.displayPrimaryCta ? (
                  <div>
                    {data?.ctaButton?.callToAction?.url ? (
                      <Link
                        to={
                          data?.ctaButton?.callToAction?.url
                            ? data?.ctaButton?.callToAction?.url
                            : '#'
                        }
                        tabindex="0"
                        target="_blank"
                      >
                        <Button
                          variant="outlined"
                          className="btn-join-us"
                          locId="displayPrimaryCta"
                          tabindex="-1"
                        >
                          {buttonLabel}
                        </Button>
                      </Link>
                    ) : (
                      <>
                        <Link
                          to={
                            data?.ctaButton?.callToAction?.slug
                              ? `/${basePath}/${data?.ctaButton?.callToAction?.slug}`
                              : '#'
                          }
                          tabindex="0"
                        >
                          <Button
                            variant="outlined"
                            className="btn-join-us"
                            locId="displayPrimaryCta"
                            tabindex="-1"
                          >
                            {buttonLabel}
                          </Button>
                        </Link>
                      </>
                    )}
                  </div>
                ) : (
                  <></>
                )}
              </div>
            </Grid>
            <Grid item xl={6} lg={6} md={6} sm={12} xs={12} className="right-image-div">
              <img
                className="image-gettouch"
                src={imgGetTouch}
                alt={imgGetTouchTitle}
                locId="imgGetTouchTitle"
              />
              </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};
export default JoinOurTeam;
