import React from 'react';
import { Grid } from '@mui/material';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import './AboutUsOurRich.scss';
const AboutUsOurRichTile = ({ heritage }) => {
  return (
    <>
      <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
        <div className="our-rich-column">
          <p className="our-rich-date" locId="dateOfMilestone">{heritage?.dateOfMilestone}</p>
          <p className="our-rich-para" locId="description">
            {heritage?.description?.raw &&
              documentToReactComponents(JSON.parse(heritage?.description?.raw))}
          </p>
        </div>
      </Grid>
    </>
  );
};
export default AboutUsOurRichTile;
