import { makeStyles } from '@material-ui/core';

export const myStyles = makeStyles(() => ({
    "bg-donuts-left": (data) => ({
        background: `url(${data?.aboutUsLeftDonutBackgroundImage?.file.url}?fm=webp&q=100) no-repeat`,
      }),

      "bg-donuts-right" :(data) => ({
        background: `url(${data?.rightDonutBackgroundImage?.file.url}?fm=webp&q=100) no-repeat`,
      }),
      "bg-capsules-bg": (data) => ({
        background: `url(${data?.capsulesBackgroundImage?.file.url}?fm=webp&q=100) no-repeat`,
      })

}))
