import { makeStyles } from '@material-ui/core';

export const myStyles = makeStyles(() => ({
  'bg-dot-matrix-and-donut': (imageData) => ({
    '&::after': {
      background: `url(${imageData?.images?.dotMatrixBackgroundImage?.file?.url}?fm=webp&q=100) no-repeat`,
    },
    background: `url(${imageData?.images?.leftDonutBackgroundImage?.file?.url}?fm=webp&q=100) no-repeat`,
  }),
  'bg-popup-close-icon': (imageData) => ({
    background: `url(${imageData?.data?.closeIcon?.file?.url}?fm=webp&q=100) no-repeat`,
  }),
}));
