import React, {useRef,useEffect, useState}  from "react";
import Carousel from 'react-elastic-carousel';
import { Grid, Box} from '@mui/material';
import './AboutUsOurRich.scss';
import AboutUsOurRichTile from './AboutUSOurRichTile';
import Container from '@material-ui/core/Container';
import { myStyles } from './styles';
import clsx from 'clsx';
import IconButton from '@material-ui/core/IconButton';
import PlayCircleFilledWhiteIcon from '@material-ui/icons/PlayCircleFilledWhite';
import PlayCircleOutlineIcon from "@material-ui/icons/PlayCircleOutline";
import PauseCircleOutlineIcon from "@material-ui/icons/PauseCircleOutline";
import { PauseCircleFilledOutlined } from "@material-ui/icons";

export default function AboutusOurRich({ autoRotateEnabled,richHeritageArrowImage, richHeritageTileSection, richHeritageTitle, backgroundImg}) {
  const classes = myStyles(backgroundImg);
  const [isHovering, setIsHovering] = useState(false);
  const [flag, setFlag] = useState(false);
  const [sliderAnimation, setSliderAnimation] = useState(true);
  const breakPoints = [
    { width: 1, itemsToShow: 2 , itemsToScroll: 1},
    { width: 428, itemsToShow: 2, itemsToScroll: 1 }, 
    { width: 500, itemsToShow: 2 , itemsToScroll: 1}, 
    { width: 700, itemsToShow: 3, itemsToScroll: 1 },
    { width: 768, itemsToShow: 3, itemsToScroll: 1 }, 
    { width: 992, itemsToShow: 4, itemsToScroll: 1 },
    { width: 1100, itemsToShow: 6 , itemsToScroll: 1},
  ];
  const carouselRef = useRef(null);
  let resetTimeout;
  const carouselProps = {
  breakPoints:breakPoints,
  itemPadding:[0, 10]
}
if(autoRotateEnabled && sliderAnimation){
  carouselProps.ref=carouselRef
  carouselProps.infiniteLoop = true
  carouselProps.enableAutoPlay= true
  carouselProps.autoPlaySpeed=5000
  carouselProps.isRTL=false
  carouselProps.onNextEnd = ({ index }) => {
    if (richHeritageTileSection.length == index + richHeritageTileSection.length / carouselRef.current.getNumOfPages()) {
      setFlag(true);
    }
  };
}
else{
  carouselProps.infiniteLoop = false
  carouselProps.ref = carouselRef;
  carouselProps.enableAutoPlay= false
}
  const buttonEl = useRef(null);
  const handleMouseOver = () => {
    setIsHovering(true);
  };
  const handleMouseOut = () => {
    setIsHovering(false);
  };
  const handlePlayBtn = () => {
    setSliderAnimation(true);
    if (flag) {
      carouselRef?.current?.goTo(0);
      setFlag(false);
    }
  };
  const handlePauseBtn = () => {
    setSliderAnimation(false);
  };
  const registerAriaLabel = ()=>{
    buttonEl?.current?.querySelectorAll(".rec-dot").forEach((role)=>{
      role.setAttribute('aria-label', 'pagination');  
    });
  }
  useEffect(() => {
    registerAriaLabel();
    if(window) 
    {
      window.addEventListener("resize",()=>{registerAriaLabel()})
    }
  }, []);
  
  return (
    <Container maxWidth="xl" className='aboutus-our-rich-cls'>
    <Grid className="aboutus-our-rich" id="ourRichHeritage">
      <Grid container>
        <Grid item container xl={2} lg={2} md={10} sm={12} xs={12} className="our-rich-heading">
          <div className="main-heading">
            <img
              src={richHeritageArrowImage?.file?.url+'?fm=webp&q=100'}
              alt={richHeritageArrowImage?.description}
              className="our-rich-arrow"
              locId='ourRichHeritageArrowImage'
            />
            <h3 className="our-rich-title" locId="ourRichHeritageTitle">{richHeritageTitle}</h3>
          </div>
        </Grid>
        <Grid item container xl={10} lg={10} md={12} sm={12} xs={12} className="our-rich-columns">
          <Grid item xl={12} lg={12} md={12} sm={12} xs={12} className="our-rich-block">
            <Box>
              <div className={clsx("our-rich-box",classes['our-rich-box'])} ref={buttonEl}>
              <>
              {autoRotateEnabled && (
              <>
          {sliderAnimation ? (
            <>
              {!isHovering && (
                <IconButton className="play-pause-icon">
                  <PauseCircleOutlineIcon
                    onMouseEnter={handleMouseOver}
                    style={{ cursor: "pointer", width: "58px", height: "58px" }}
                    
                  />
                </IconButton>
              )}
              {isHovering && (
                <IconButton className="play-pause-icon" onClick={handlePauseBtn}>
                  <PauseCircleFilledOutlined
                    style={{ cursor: "pointer", width: "58px", height: "58px" }}
                    onMouseLeave={handleMouseOut}
                    
                  />
                </IconButton>
              )}
            </>
          ) : (
            <>
              {!isHovering && (
                <IconButton className="play-pause-icon">
                  <PlayCircleOutlineIcon
                    onMouseEnter={handleMouseOver}
                    style={{ cursor: "pointer", width: "58px", height: "58px" }}
                    
                  />
                </IconButton>
              )}
              {isHovering && (
                <IconButton className="play-pause-icon" onClick={handlePlayBtn}>
                  <PlayCircleFilledWhiteIcon
                    style={{ cursor: "pointer", width: "58px", height: "58px" }}
                    onMouseLeave={handleMouseOut}
                    
                  />
                </IconButton>
              )}
            </>
          )}
          </> 
          )}
          </>
                <Carousel  {...carouselProps}>
                  {richHeritageTileSection.map((heritage,i) => (
                    <AboutUsOurRichTile key={i} heritage={heritage} />
                  ))}
                </Carousel>
              </div>
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
    </Container>
  );
}
