import React from 'react';
import { Paper } from '@material-ui/core';
import VideoTiles from '../VideoTiles';

const ImageVideoComponent = (props) => {
  const { storyhistorySection, rootClass, thumbnailAltText } = props;
  return (
    <>
      {storyhistorySection?.isVideo && (
        <VideoTiles
          thumbnailClass={'video-img'}
          rootClass={rootClass}
          thumbnailOverLay={'video-overlay'}
          data={storyhistorySection?.videoComponent}
          thumbnailAltText={thumbnailAltText}
        />
      )}
      {!storyhistorySection?.isVideo && (
        <Paper elevation={0} className="video-image-container">
          <img
            src={storyhistorySection?.image?.file?.url+'?fm=webp&q=100'}
            alt={storyhistorySection?.image?.description}
            className="video-img"
            locId="storyhistorySectionImage"
          />
        </Paper>
      )}
    </>
  );
};
export default ImageVideoComponent;
