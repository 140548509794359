import { makeStyles } from '@material-ui/core';

export const myStyles = makeStyles(() => ({
    "bg-our-winning-section": (data) => ({
        background: `url(${data?.file?.url}?fm=webp&q=100) no-repeat`,
    }),
    'bg-dot-matrix': (data) => ({
    '&:after': {
        backgroundImage: `url(${data?.file?.url}?fm=webp&q=100)`,
    }})
}))