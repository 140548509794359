import { makeStyles } from '@material-ui/core';

export const myStyles = makeStyles(() => ({
  'landing-hero-img-div': (data) => ({
    '&::after': {
      background: `url(${data?.dotMatrixBackgroundImage?.file.url}?fm=webp&q=100) no-repeat`,
    },
  }),
  'txt-with-video-container': (data) => ({
    '&::before': {
      background: `url(${data?.capsuleLeftBackgroundImage?.file.url}?fm=webp&q=100) no-repeat`,
    },
  }),
  'blue-round-div': (data) => ({
    background: `url(${data?.circularSectionBackgroundImage?.file.url}?fm=webp&q=100)`,
  }),
  'image-box':(data)=>({
    '&::after':{
        background: `url(${data?.dotMatrixBackgroundImage?.file.url}?fm=webp&q=100) no-repeat`,
    }
  })
}));
