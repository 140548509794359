import React from 'react'
import Grid from '@material-ui/core/Grid';
// import { Button } from '@material-ui/core';
import './AboutUsOurWinning.scss'
import clsx from 'clsx';
import { myStyles } from './style';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { Link } from 'gatsby';
import Container from '@material-ui/core/Container';

export default function AboutUsOurWinning({ backgroundImage, ctaLabel, externalLinkUrl, longDescription, titleImageShortDecription, dotMatrixBackgroundImage }) {
  
  const classes = myStyles(backgroundImage);
  const classesdotmatrix = myStyles(dotMatrixBackgroundImage);
  return (
    <>
     <Container maxWidth="xl" className='aboutus-winning-cls'>
      <Grid container className="aboutus-winning-div" id="winningBehaviour">
        <Grid item container xl={12} lg={12} md={12} sm={12} xs={12}
          className={clsx('txt-with-blue-section', classes['bg-our-winning-section'], classesdotmatrix['bg-dot-matrix'])}
          locId="ourWinningBehaviorsBackgroundImage">
          <div className="txt-with-blue-section-overlay"></div>
          <Grid item container className='title-div'>
            <Grid item xl={3} lg={4} md={4} sm={4} xs={5} spacing={2}>
              <h2 className='title' locId="winningBehaviorsTitleShortDescriptionLogoname" >
                {titleImageShortDecription?.name}
              </h2>
            </Grid>
          </Grid>
          <Grid item container xl={12} lg={12} md={12} sm={12} xs={12} className='rich-text-div'>
            <Grid item xl={6} lg={6} md={12} sm={12} xs={12} >
              <Grid item xl={12} lg={12} md={12} sm={12} xs={12} >
                <p className='left-para' locId="winningBehaviorsTitleShortDescription">
                  {documentToReactComponents(JSON.parse(titleImageShortDecription?.description?.raw))}
                </p>
              </Grid>
            </Grid>
            <Grid item xl={6} lg={6} md={12} sm={12} xs={12}>
              <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                <p className='right-para' locId="winningBehaviorsLongDescription" >
                  {documentToReactComponents(JSON.parse(longDescription?.raw))}
                </p>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xl={12} lg={12} md={12} sm={12} xs={12} spacing={2}>
            <p className="logo-with-btn">
              <img src={titleImageShortDecription?.image?.file?.url+'?fm=webp&q=100'}
                alt={titleImageShortDecription?.image?.description}
                locId="winningBehaviorsTitleShortDescriptionLogoimage"
                width="100%" />
               
              <div locId="ourWinningBehaviorsButtonLabel" >
                <Link className='button-txt' to={externalLinkUrl} locId="ourWinningBehaviorsUrl"
                  tabIndex="0" target='blank'>
                  {ctaLabel}
                </Link>
              </div>
            </p>
          </Grid>
        </Grid>
      </Grid>
      </Container>
    </>
  )
}


