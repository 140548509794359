import React, { useState } from 'react';
import { Grid, Card, CardContent, Typography } from '@material-ui/core';
import './AboutUsOurShining.scss';
import { myStyles } from './styles';
import { Link } from 'gatsby';
import clsx from 'clsx';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import BioPopUp from '../BioPopUp';

const AboutUsOurShining = ({ data, basePath, backgroundImg }) => {
  const [activeIndex, setActiveIndex] = useState(-1);
  const [open, setOpen] = useState(false);
  const handleClick = (index) => {
    setActiveIndex(index);
    setOpen(true);
  };
  const handleEnterKeyPress = (index, e) => {
    if (e.charCode == 13) {
      setActiveIndex(index);
      setOpen(true);
    }
  };
  const numberOfProfiles = process.env.GATSBY_NUMBER_OF_SHINING_JEWELS_IN_ABOUTUS;
  let AuthorList = [...data?.ourShiningJewelsSection].splice(0, numberOfProfiles);
  const slug = data?.viewMore?.slug;
  const label = data?.viewMore?.label;
  const classes = myStyles(data);
  return (
    <Grid container className="about-ourshining">
      <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
      <Grid item xl={4} lg={4} md={12} sm={12} xs={12}>
        <h2
          className="title"
          locId="ourShiningJewelsHeader"
        >
          {data?.ourShiningJewelsHeader}
        </h2>
        </Grid>
      <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
        <h3 className="sub-title" locId="ourShiningJewelsSubHeader">
          {data?.ourShiningJewelsSubHeader}
        </h3>
      </Grid>
      </Grid>
      {/* <Grid item container xl={12} lg={12} md={12} sm={12} xs={12} className={clsx("capsules",classes['capsules-bg'])} ></Grid> */}
      <Grid container spacing={2}>
        {AuthorList.map((person, index) => {
          return (
            <>
              <Grid item xl={3} lg={3} md={4} sm={5} xs={6}>
                <Card
                  className="thumb-card"
                  onClick={() => {
                    handleClick(index);
                  }}
                  onKeyPress={(e) => {
                    handleEnterKeyPress(index, e);
                  }}
                  tabIndex="0">
                  <img
                    src={person?.image?.file.url+'?fm=webp&q=100'}
                    alt={person?.image?.description}
                    locId={person?.name}
                  />
                   <CardContent className="descp">
                    <Typography gutterBottom variant="h5" component="h4" locId="authorName">
                      {person?.name}
                    </Typography>
                    <Typography variant="body2" component="p" locId="authorDesignation">
                      {person?.designation}
                    </Typography>
                    {person?.region && documentToReactComponents(JSON.parse(person?.region?.raw))}
                  </CardContent>
                </Card>
              </Grid>
              {index == activeIndex ? (
                <BioPopUp data={person} open={open} setOpen={setOpen} backgroundImg={backgroundImg}/>
              ) : (
                <></>
              )}
              {(index === 1 || index === 3 || index === 5) && (
                <Grid item xl={3} lg={3} md={4} sm={5} xs={6}>
                  &nbsp;
                </Grid>
              )}
              {index === 6 && (
                <Grid item xl={3} lg={3} md={4} sm={5} xs={6}>
                  &nbsp;
                </Grid>
              )}
              {index === 9 && (
                <Grid item xl={3} lg={3} md={4} sm={5} xs={6}>
                  &nbsp;
                </Grid>
              )}
              {index === 10 && (
                <Grid item xl={6} lg={6} md={8} sm={5} xs={6}>
                  &nbsp;
                </Grid>
              )}
            </>
          );
        })}
      </Grid>
      <Grid container spacing={1} className="view-more-text" locid="viewMore">
        <Grid item xl={12} lg={12} md={12} sm={12} xs={12} className="view-more-col">
          <Link to={`/${basePath}/` + slug}>
            <Typography className="view-more-text">{label}</Typography>
          </Link>
        </Grid>
      </Grid>
    </Grid>
  );
};
export default AboutUsOurShining;
