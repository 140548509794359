import React from 'react';
import './AboutUsAchievement.scss';
import Grid from '@material-ui/core/Grid';
import AwardsSlider from '../AwardsSlider';

const AboutUsAchievement = ({ image, backgroundImg, autoRotateEnabled }) => {
  return (
    <>
      <Grid item container xl={12} lg={12} md={12} sm={12} xs={12} className="our-about-cls" locId="awardandRecognition">
        <Grid item container xl={12} lg={12} md={12} sm={12} xs={12}>
          <AwardsSlider awardsSection={image} backgroundImg={backgroundImg} autoRotateEnabled={autoRotateEnabled}/>
        </Grid>
      </Grid>
    </>
  );
};

export default AboutUsAchievement;
