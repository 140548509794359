import React from 'react';
import './AboutUsPress.scss';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import { Link } from 'gatsby';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';

export default function AboutusPress({ pressTitle, pressData, viewPressRoom, basePath }) {
  const richtextOptions = {
    renderText: (text) => {
      return text.split('\\n').reduce((children, textSegment, index) => {
        return [...children, index > 0 && <br key={index} />, textSegment];
      }, []);
    },
  };
  const numberOfTileOnPage = parseInt(process.env.GATSBY_NUMBER_OF_TILE_FOR_PODCAST_IN_RESOURCEHUB);
  const months = [
    'JANUARY',
    'FEBRUARY',
    'MARCH',
    'APRIL',
    'MAY',
    'JUNE',
    'JULY',
    'AUGUST',
    'SEPTEMBER',
    'OCTOBER',
    'NOVEMBER',
    'DECEMBER',
  ];
  const pressList = pressData.map((pressData) => {
    let dateinTimestamp;
    dateinTimestamp = new Date(pressData?.pressReleaseConent?.dateOfPublication).getTime();
    const month = new Date(Number(dateinTimestamp)).getMonth();
    const monthString = months[month];
    const day = new Date(Number(dateinTimestamp)).getDate();
    const formatedDateString =
      monthString + ' ' + day + ', ' + new Date(Number(dateinTimestamp)).getFullYear();
    return {
      ...pressData,
      publishingDate: formatedDateString,
      dateinTimestamp: dateinTimestamp,
    };
  });
  function comparePublishingDate(a, b) {
    return b.dateinTimestamp - a.dateinTimestamp;
  }
  pressList.sort(comparePublishingDate);
  const pressInfo = pressList.slice(0, numberOfTileOnPage);
  const pressContent1=pressInfo[0]?.pressReleaseConent;
  const pressContent2=pressInfo[1]?.pressReleaseConent;

  return (
    <Grid className="about-us-press-cls">
      <h2 className="title-press-release" locId="pressReleasesTitle">
        {pressTitle}
      </h2>
      <Grid
        container
        spacing={2}
        className="secure-container-about"
        sx={{ backgroundColor: '#FFFFFF' }}
      >
        <Grid item xl={5} lg={5} md={5} sm={12} xs={12}>
          <Link to={`/${basePath}/` + pressInfo[0].slug}>
            <div>
              <div className="date-year" locId="dateOfPublishing">
                {pressInfo[0]?.publishingDate}
              </div>
              <h3 className="title-press-about" locId="title">
                {pressContent1?.pressContentTitle
                  ? pressContent1?.pressContentTitle
                  : pressContent1?.pressReleaseTitle}
              </h3>
              {pressContent1?.description?.raw &&
                documentToReactComponents(
                  JSON.parse(pressContent1?.description?.raw),
                  richtextOptions
                )}
            </div>
          </Link>
        </Grid>
        <Grid item xl={2} lg={2} md={2}>
          &nbsp;
        </Grid>
        <Grid item xl={5} lg={5} md={5} sm={12} xs={12}>
          <Link to={`/${basePath}/` + pressInfo[1].slug}>
            <div>
              <div className="date-year" locId="dateOfPublishing">
                {pressInfo[1]?.publishingDate}
              </div>
              <h3 className="title-press-about" locId="title">
                {pressContent2?.pressContentTitle
                  ? pressContent2?.pressContentTitle
                  : pressContent2?.pressReleaseTitle}
              </h3>
              {pressContent2?.description?.raw &&
                documentToReactComponents(
                  JSON.parse(pressContent2?.description?.raw),
                  richtextOptions
                )}
            </div>
          </Link>
        </Grid>
      </Grid>
      <div>
        {(viewPressRoom?.label || viewPressRoom) &&
          (viewPressRoom?.link?.url ? (
            <Button
              variant="outlined"
              className="btn-press-room"
              locId="buttonLabel"
              tabIndex="0"
              href={viewPressRoom?.link?.url ? viewPressRoom?.link?.url : '#'}
            >
              {viewPressRoom?.label}
            </Button>
          ) : (
            <Button
              variant="outlined"
              className="btn-press-room"
              locId="buttonLabel"
              tabIndex="0"
              href={viewPressRoom?.link?.slug ? `/${basePath}/${viewPressRoom?.link?.slug}` : '#'}
            >
              {viewPressRoom?.label}
            </Button>
          ))}
      </div>
    </Grid>
  );
}
